import React, { useState, useCallback, useEffect, useContext } from "react";
import styled from "styled-components";
import { Spin, Input as AntInput, Select as AntSelect } from "antd";
import * as Theme from "../Theme";
import { Context } from "../AppContext";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import CaretDownOutlined from "@ant-design/icons/CaretDownOutlined";
import { errorHandler } from "../errors";

export default function AddressField({
  onChange,
  zip_code,
  city,
  district,
  address = "",
}) {
  const [loading, setLoading] = useState(false);
  const [counties, setCounties] = useState([]);
  const [towns, setTowns] = useState([]);

  const app = useContext(Context);

  const getZipCode = useCallback(
    async zip_code => {
      setLoading(true);
      try {
        let resp = await app.actions.getZipCode(zip_code);
        if (resp) {
          onChange({
            zip_code,
            city: resp.countyName,
            district: resp.townName,
            address,
          });
        }
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    },
    [address]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let resp = await app.actions.getCounties();
        setCounties(resp);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    })();
  }, []);

  const getDistricts = useCallback(async () => {
    if (city) {
      setLoading(true);
      try {
        let resp = await app.actions.getDistricts(city);
        setTowns(resp);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    }
  }, [city]);

  useEffect(() => {
    getDistricts();
  }, [getDistricts]);

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 16 }} />}
        style={{ alignSelf: "center", marginRight: 10 }}
      />
      <Input
        placeholder="郵遞區號"
        type="short"
        disabled={loading}
        value={zip_code}
        onChange={e => {
          let value = e.target.value;
          onChange({ city, district, address, zip_code: value });
        }}
        onBlur={() => {
          if (/[0-9]{3}/g.test(zip_code)) {
            getZipCode(zip_code);
          }
        }}
        style={{ marginRight: 10 }}
      />

      <Select
        placeholder="縣市"
        value={city}
        onChange={value => {
          onChange({
            zip_code: undefined,
            city: value,
            district: undefined,
            address,
          });
        }}
        disabled={loading}
        style={{ marginRight: 10 }}
      >
        {counties.map(c => (
          <SelectOption key={c.countyName} value={c.countyName}>
            {c.countyName}
          </SelectOption>
        ))}
      </Select>

      <Select
        placeholder="鄉鎮市區"
        value={district}
        onChange={value => {
          // set zipcode
          let instance = towns.find(t => t.townName === value);

          onChange({
            zip_code: instance?.zipCode || zip_code,
            city,
            district: value,
            address,
          });
        }}
        disabled={loading}
        style={{ marginRight: 10 }}
      >
        {towns.map(t => (
          <SelectOption key={t.townName} value={t.townName}>
            {t.townName}
          </SelectOption>
        ))}
      </Select>

      <Input
        placeholder="詳細地址路段"
        disabled={loading}
        value={address}
        type="long"
        onChange={e =>
          onChange({
            zip_code,
            city,
            district,
            address: e.target.value,
          })
        }
      />
    </div>
  );
}

const Select = styled(AntSelect).attrs({
  suffixIcon: <CaretDownOutlined style={{ fontSize: 14, color: "#707070" }} />,
  bordered: false,
  dropdownMatchSelectWidth: false,
})`
  flex-basis: 150px;
  padding: 3px 0;
  margin: 6px 0px;
  border-bottom: solid 1px #707070;
  background-color: ${Theme.colors.sub};

  :focus {
    border-color: ${Theme.colors.main};
    outline-color: ${Theme.colors.main}4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
    box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
  }
`;

const SelectOption = styled(AntSelect.Option)`
  width: 150px;
`;

const Input = styled(AntInput)`
  padding: 8px 16px;
  border: none;
  background-color: ${Theme.colors.sub};
  margin: 6px 0px;
  flex-basis: 220px;
  flex-shrink: 0;
  ${props => (props.type === "short" ? "flex-basis: 90px; width: 90px;" : "")}
  ${props => (props.type === "long" ? "flex-basis: 320px;" : "")}

  @media only screen and  (max-width: ${Theme.breakpoints.xs}px) {
    flex-basis: ${props =>
      props.type === "long" ? "220px" : props.type === "short" ? "90px" : ""};
    flex-shrink: 1;
  }

  :hover {
    border-color: ${Theme.colors.main}4;
  }

  :focus {
    border-color: ${Theme.colors.main};
    outline-color: ${Theme.colors.main}4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
    box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
  }

  .ant-input::selection {
    border-color: green;
  }

  /* ::after {
    border-color: green;
    background: green;
} */

`;
